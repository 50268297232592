import React, { FC } from 'react';
import { Link } from 'gatsby';

import textTruncate from 'utils/textTruncate';
import { SearchResultItemProps } from './models';
import './SearchResultItem.scss';

export const SearchResultItem: FC<SearchResultItemProps> = ({ name, description, url }) => (
  <li className="search-result">
    <div className="search-result__wrapper">
      <Link className="search-result__title" to={url}>
        {name}
      </Link>
      <div
        className="search-result__description"
        dangerouslySetInnerHTML={{ __html: textTruncate(description) }}
      />
    </div>
  </li>
);

export default SearchResultItem;
