const textTruncate = (text?: string, maxLettersNumber: number = 180) => {
  const suffix = '...';
  if (!text) {
    return '';
  }

  if (text.length >= maxLettersNumber) {
    const cuttenText = text.substring(0, maxLettersNumber);
    const lastFullWordPosition = cuttenText.lastIndexOf(' ');

    return `${cuttenText.substring(0, lastFullWordPosition)}${suffix}`;
  }

  return text;
};

export default textTruncate;
