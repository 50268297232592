import React, { FC } from 'react';

import { SearchResultsSummaryProps } from './models';

import './SearchResultsSummary.scss';

const SearchResultsSummary: FC<SearchResultsSummaryProps> = ({
  foundText,
  seenResults,
  total,
  combined,
}) => (
  <div data-testid="search-results-summary" className="search-results-summary">
    <div className="search-results-summary__wrapper">
      <span>
        {foundText}
        <strong>{` ${seenResults - combined.length + 1}-${seenResults} `}</strong>
        of
        <strong>{` ${total}`}</strong>
      </span>
    </div>
  </div>
);

export default SearchResultsSummary;
