import React, { FC } from 'react';

import SearchResultItem from 'components/SearchResults/SearchResultItem';
import './SearchResults.scss';
import classNames from 'classnames';
import { SearchResultProps } from './models';

export const SearchResults: FC<SearchResultProps> = ({ searchResults, notFoundText }) => (
  <div className={classNames('search-results', !searchResults.length && 'no-results')}>
    {searchResults.length > 0 ? (
      <ul className="search-results__wrapper">
        {searchResults.map((item) => (
          <SearchResultItem key={item.objectID} {...item} />
        ))}
      </ul>
    ) : (
      <p>{notFoundText}</p>
    )}
    <div className="search-results__shadow" />
  </div>
);

export default SearchResults;
