import React, { FC, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { graphql, navigate } from 'gatsby';

import Layout from 'layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchBar from 'common/SearchBar';
import SectionTitle from 'common/SectionTitle';
import SearchResults from 'components/SearchResults';
import Pagination from 'components/Pagination';
import SearchResultsSummary from 'components/SearchResultsSummary';
import { isBrowser } from 'utils/browser';

import createBreadcrumbs from 'utils/createBreadcrumbs';

import getQueryParamByName from 'utils/getQueryParamByName';
import { SearchResultItemProps } from '../../components/SearchResults/SearchResultItem/models';
import { SearchResultPageProps } from './models';

import './SearchResultPage.scss';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_PUBLIC_KEY as string
) as any;

const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX);

const SearchResultPage: FC<SearchResultPageProps> = ({
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  data: {
    searchResults: {
      title,
      seoNoIndex,
      seoMetaTitle,
      seoMetaDescription,
      searchText,
      notFoundText,
      seoMetaKeywords,
      foundText,
      itemsPerPage,
    },
    allSitePage: { nodes },
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  const searchPage = (isBrowser() && getQueryParamByName('page')) || '1';
  const searchQuery = (isBrowser() && getQueryParamByName('q')) || '';
  const [currentPage, setCurrentPage] = useState(parseInt(searchPage, 10));

  const [searchResults, setSearchResults] = useState<{
    combined: SearchResultItemProps[];
    query: string;
    total: number;
    seenResults: number;
  }>({
    combined: [],
    query: '',
    total: 0,
    seenResults: 0,
  });

  const getSeenResultsNumber = (searchData) => {
    const { hits, hitsPerPage, page } = searchData;

    return hits.length === hitsPerPage && hits.length > 0
      ? hits.length * currentPage
      : hitsPerPage * page + hits.length;
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    navigate(`/search-results/?q=${searchQuery}&page=${page}`);
  };

  useEffect(() => {
    const pageQueryNumber = parseInt(getQueryParamByName('page') || '1', 10);

    if (!searchQuery) return;

    if (searchQuery) {
      index
        .search(searchQuery, {
          page: pageQueryNumber - 1,
          hitsPerPage: itemsPerPage,
        })
        .then((search) => {
          setCurrentPage(search.page + 1);
          setSearchResults({
            combined: search.hits,
            query: searchQuery,
            total: search.nbHits,
            seenResults: getSeenResultsNumber(search),
          });
        });
    }
  }, [searchQuery, currentPage, itemsPerPage]);

  return (
    <Layout
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <Container className="search-result-page page">
        <Row className="justify-content-lg-center">
          <Col md="12">
            <SectionTitle text={title} isMainTitle />
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Col lg="7">
            <SearchBar className="search-result-page__search-bar" text={searchText} showInput />
          </Col>
        </Row>
        {searchResults.combined.length > 0 ? (
          <Row className="justify-content-lg-center">
            <Col lg="7">
              <SearchResultsSummary foundText={foundText} {...searchResults} />
            </Col>
          </Row>
        ) : null}

        <Row className="justify-content-lg-center">
          <Col lg="7">
            <SearchResults searchResults={searchResults.combined} notFoundText={notFoundText} />
          </Col>
        </Row>

        <Pagination
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          totalItems={searchResults.total}
          activePage={currentPage}
        />
      </Container>
    </Layout>
  );
};

export default SearchResultPage;

export const query = graphql`
  {
    searchResults {
      title
      searchText
      notFoundText
      foundText
      itemsPerPage
      ...SeoFragment
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;
